import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Link } from "react-router-dom";

import { useSpring, a, to as springTo, SpringConfig } from "@react-spring/web";
import { defaultSpringConfig } from "components/Animations/SpringProperties/SpringProperties";

import classNames from "classnames";

import { preloadRouteComponent } from "app/routes";

import { useTimeout } from "utils/Index";

export interface IOwnSpecificProps {
  to?: string;
  disabled?: boolean;
  type: "extraSmall" | "small" | "medium" | "large";
  scheme?: string;
  backgroundShade?: "light" | "dark";
  backgroundColor?: string;
  arrow?: boolean;
  id?: string;
  isActive?: boolean;
  animateIn?: boolean;
  animationReady?: boolean;
  onClick?: () => void;
  title: string;
}

type IOwnProps = IOwnSpecificProps;

type IProps = IOwnProps;

const ButtonLinkComponent: React.FC<IProps> = ({
  animateIn,
  animationReady,
  to = null,
  disabled = false,
  type,
  scheme,
  id,
  isActive,
  backgroundShade,
  backgroundColor,
  title,
  arrow,
  onClick = () => null,
  ...props
}) => {
  const [clicked, setClicked] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [backgroundWidth, setBackgroundWidth] = useState(0);
  const [textMargin, setTextMargin] = useState(0);

  const [delay, setDelay] = useState(500);
  const [triggerTimeout, setTriggerTimeout] = useState(false);

  useTimeout(
    () => {
      setClicked(false);
    },
    triggerTimeout ? delay : null
  );

  useEffect(() => {
    let newBackgroundWidth;
    let newTextMargin;

    if (type === "large") {
      newBackgroundWidth = 60;
      newTextMargin = 20;
    } else if (type === "medium" || type === "small" || type === "extraSmall") {
      newBackgroundWidth = 30;
      newTextMargin = 10;
    }
    if (newBackgroundWidth !== backgroundWidth)
      setBackgroundWidth(newBackgroundWidth);
    if (newTextMargin !== textMargin) setTextMargin(newTextMargin);
  }, [backgroundWidth, textMargin, type]);

  const handleClick = () => {
    if (!disabled) {
      onClick();
      setTriggerTimeout(false);
      setClicked(true);
      setTriggerTimeout(true);
    }
  };

  const handleMouseEnter = () => {
    if (!disabled) {
      if (!hovering) setHovering(true);
      if (to != null) preloadRouteComponent(to);
    }
  };

  const handleMouseLeave = () => {
    if (!disabled) {
      if (hovering || clicked) {
        setHovering(false);
        setClicked(false);
      }
    }
  };

  const ctaArrow = () => {
    if (arrow) return <span className="arrow">&#8250;</span>;
    else return null;
  };

  const backgroundStartRatio = 8;

  const inputProps: { [k: string]: any } = {
    className: classNames(
      "button-link",
      type,
      { [scheme]: scheme != null },
      { ["background-" + backgroundShade]: backgroundShade != null },
      { disabled }
    ),
    id,
    onClick: handleClick,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    title,
  };

  const backgroundSpringConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 50,
    friction: 36,
  };

  const [backgroundInitialisationSpring, setBackgroundInitialisation] =
    useSpring(() => ({
      x: 0,
      config: backgroundSpringConfig,
    }));

  const animateInSpringConfig: SpringConfig = {
    ...backgroundInitialisationSpring,
    tension: 35,
  };

  const [animateInSpring, setAnimateInSpring] = useSpring(() => ({
    x: 0,
    config: animateInSpringConfig,
  }));

  useEffect(() => {
    setBackgroundInitialisation({ x: animationReady && animateIn ? 1 : 0 });
    setAnimateInSpring({ x: animationReady && animateIn ? 1 : 0 });
  }, [animationReady, animateIn]);

  const hoveringSpringConfig: SpringConfig = {
    ...backgroundInitialisationSpring,
    tension: 340,
  };

  const [hoveringSpring, setHoveringSpring] = useSpring(() => ({
    x: 0,
    config: hoveringSpringConfig,
  }));

  useEffect(() => {
    setHoveringSpring({ x: hovering ? 1 : 0 });
  }, [hovering]);

  const [hoveringClickedSpring, setHoveringClickedSpring] = useSpring(() => ({
    x: 0,
    config: hoveringSpringConfig,
  }));

  useEffect(() => {
    setHoveringClickedSpring({ x: hovering ? (clicked ? 10 : 7) : 0 });
  }, [hovering, clicked]);

  const backgroundStyles = {
    backgroundColor: backgroundColor != null ? backgroundColor : null,
    transform: springTo(
      [
        backgroundInitialisationSpring.x,
        hoveringSpring.x,
        hoveringClickedSpring.x,
      ],
      (backgroundInitialisation, y, z) =>
        `translateX(calc( ((${
          backgroundInitialisation <= backgroundStartRatio / 10 ? 1 : 0
        } * -200%) + (${
          backgroundInitialisation <= backgroundStartRatio / 10
            ? backgroundInitialisation * (10 / backgroundStartRatio)
            : 0
        } * 300%)) + ((${
          backgroundInitialisation > backgroundStartRatio / 10 ? 1 : 0
        } * -100%) + (${
          backgroundInitialisation > backgroundStartRatio / 10
            ? (backgroundInitialisation - backgroundStartRatio / 10) *
              (10 / (10 - backgroundStartRatio))
            : 0
        } * ${backgroundWidth}px)) + (${y} * -${backgroundWidth}px) + (${z} * 10%) ))`
    ),
  };

  const labelStyles = {
    transform: animateInSpring.x.to(
      (x) => `translateX(calc((${x} * ${textMargin}px) - ${textMargin}px))`
    ),
  };

  const LinkBody = (
    <div>
      <a.div className="background" style={backgroundStyles} />
      <a.div className="label" style={labelStyles}>
        {props.children} {ctaArrow()}
      </a.div>
    </div>
  );

  const LinkText = (
    <span className="spacerText">
      {props.children} {ctaArrow()}
    </span>
  );

  if (to == null)
    return (
      <div {...inputProps}>
        {LinkBody}
        {LinkText}
      </div>
    );
  else
    return (
      <Link to={to} {...inputProps}>
        {LinkBody}
        {LinkText}
      </Link>
    );
};

export const ButtonLink = ButtonLinkComponent;

import React, { useLayoutEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import * as fromViewport from 'store/Viewport';

export const useViewportDimensions = () => {
  const dispatch = useDispatch();

  const updateViewportWidthAction = useCallback(
    (width: number) => dispatch(fromViewport.actionCreators.updateViewportWidth(width))
    , [dispatch]);

  useLayoutEffect(() => {

    const updateWindowDimensions = () => {
      const { innerWidth: width } = window;
      updateViewportWidthAction(width);
    };

    updateWindowDimensions();

    // Setup event listeners on initial mount to keep the  dimensions of the slider updated
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);
};

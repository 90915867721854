import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as fromUserInterface from 'store/UserInterface';

import Screen from './Screen';

const LoadingScreen: React.FC<{}> = () => {
  const loadingScreens = useSelector(fromUserInterface.getLoadingScreens);

  return (
    <div className='loadingScreenWrapper'>
      {Object.keys(loadingScreens).map(id => {
        return <Screen key={id} id={id} {...loadingScreens[id]} />;
      })}
    </div>
  );
};

export default LoadingScreen;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";

import * as fromUserInterface from "store/UserInterface";

import { useSpring, a, to } from "@react-spring/web";

import { useLatest } from "utils/Index";

import { useAnimateInTrail } from "hooks/useAnimateInTrail";
import {
  initialOffset,
  animateInSpringConfig,
  animationTriggerThreshold,
} from "components/Animations/SpringProperties/SpringProperties";

import { ButtonLink } from "components/Links/Index";
import { useCookie } from "react-use";

const CookieModalComponent: React.FC<{}> = (props) => {
  const animateContentIn = useSelector(
    fromUserInterface.getPageContentAnimatedIn
  );

  const [isActive, setIsActive] = useState(false);
  const [hideModal, setHideModal] = useState(false);
  const [animationReady, setAnimationReady] = useState(false);
  const [animateIn, setAnimateIn] = useState(false);
  const [cookieModalDismissed, updateCookieModalDismissed] = useCookie(
    "cookieModalDismissed"
  );
  const [cookieOptions] = useState({
    path: "/",
    expires: 365.25,
    secure: false,
    httpOnly: false,
    sameSite: "strict",
  } as Cookies.CookieAttributes);

  useEffect(() => {
    if (animateContentIn && !animateIn) setAnimateIn(true);
  }, [animateContentIn, animateIn]);

  useEffect(() => {
    const modalDismissed: boolean =
      cookieModalDismissed === "true" ? true : false;
    updateCookieModalDismissed(modalDismissed.toString(), cookieOptions);

    setIsActive(!modalDismissed);
    setHideModal(modalDismissed);
    setAnimationReady(modalDismissed ? false : true);
  }, [cookieOptions]);

  const closeModal = () => {
    updateCookieModalDismissed("true", cookieOptions);
    if (isActive) setHideModal(true);
  };

  const latestIsActive = useLatest(isActive);
  const latestHideModal = useLatest(hideModal);

  const disableCookieModal = () =>
    latestIsActive && latestHideModal ? setIsActive(false) : null;

  const [animateInTrail, triggerAnimateIn] = useAnimateInTrail(
    animationReady && animateIn,
    4,
    {
      notify: [
        {
          index: 3,
          threshold: animationTriggerThreshold,
        },
      ],
    }
  );
  const [animateOutSpring, setAnimateOutSpring] = useSpring(() => ({
    x: 1,
    config: animateInSpringConfig,
    onRest: () => disableCookieModal(),
  }));

  useEffect(() => {
    setAnimateOutSpring({ x: hideModal ? 0 : 1 });
  }, [hideModal]);

  const modalWrapperStyles = {
    opacity: to(
      [animateInTrail[0].active, animateOutSpring.x],
      (anin, anout) => anin * anout * (isActive ? 1 : 0)
    ),
    transform: animateInTrail[0].active.to(
      (x) => `translateY(calc(${initialOffset(x)}))`
    ),
  };

  const closeButtonStyles = {
    opacity: animateInTrail[1].active,
  };

  const titleStyles = closeButtonStyles;

  const descriptionStyles = {
    opacity: animateInTrail[2].active,
  };

  const ctaStyles = {
    opacity: animateInTrail[3].active,
  };

  if (animationReady)
    return (
      <a.div
        className={classNames("cookieModalWrapper", { active: isActive })}
        style={modalWrapperStyles}
      >
        <div className="background" />
        <a.div className="closeButton" style={closeButtonStyles}>
          <div className="trigger" onClick={closeModal}>
            <span />
            <span />
          </div>
        </a.div>
        <a.h2 style={titleStyles}>Who doesn't like cookies?</a.h2>
        <a.p style={descriptionStyles}>
          We use cookies to enhance your browsing experience as well as to help
          personalise our content and site for you . We'll just assume you're
          fine with that whilst you continue using our site. Sweet.
        </a.p>
        <a.div className="ctaWrapper" style={ctaStyles}>
          <ButtonLink
            onClick={closeModal}
            type="extraSmall"
            arrow
            animationReady={animationReady}
            animateIn={triggerAnimateIn(3)}
            title="Sounds good"
          >
            Sounds good
          </ButtonLink>
        </a.div>
      </a.div>
    );
  else return null;
};

export default CookieModalComponent;

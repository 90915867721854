import { SpringConfig, SpringValue, SpringValues } from '@react-spring/web';

export interface ISpringProperties {
  stiffness: number;
  damping: number;
}

export const animateComponentInStart: ISpringProperties = {
  stiffness: 100,
  damping: 25,
};

export const animateComponentInEnd: ISpringProperties = {
  stiffness: 120,
  damping: 25,
};

export const animateComponentImageInStart: ISpringProperties = {
  stiffness: 60,
  damping: 25,
};

export const animateComponentImageInEnd: ISpringProperties = {
  stiffness: 60,
  damping: 25,
};

export const tileStaggerGap: number = 4;
export const draggableTileStaggerGap: number = 3;
export const animationTriggerThreshold: number = 0.0000001;

export const defaultSpringConfig: SpringConfig = {
  mass: 1,
  tension: 170,
  friction: 36,
  clamp: true,
  precision: 0.01,
  velocity: 0,
  //duration: undefined,
  easing: t => t,
};

export interface ISpring {
  [id: string]: SpringValue<number>;
}

export const animateInSpringConfig: SpringConfig = {
  ...defaultSpringConfig,
};

export const initialOffset = (x: number, offset: number = 30) => (`(${x} * -1 * ${offset}px) + ${offset}px`);

export type IBackgroundShade = 'light' | 'dark';

// There is a good spring visualiser at https://react-spring-visualizer.com/#spring where you can design spring animations
// Other useful visualisation and dev tools https://twitter.com/stefanjudis/status/1216788482972168193
// To make even better css shadows https://brumm.af/shadows

import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BlockLink from "components/Links/BlockLink/BlockLink";

import { useSpring, a, to, SpringConfig } from "@react-spring/web";
import { defaultSpringConfig } from "components/Animations/SpringProperties/SpringProperties";
import { useDisabledScrollWidth } from "hooks/useDisabledScrollWidth";

import { Logo } from "components/Logo/Logo";

//import { Stage, Layer, Rect, Text, Image } from 'react-konva';

import classNames from "classnames";

import * as fromUserInterface from "store/UserInterface";
import * as fromMainNavigation from "store/MainNavigation";

import { useInterval, useLatest } from "utils/Index";

const HeaderMenuComponent: React.FC<{}> = () => {
  const [animationReady, setAnimationReady] = useState(false);

  const animateContentIn = useSelector(
    fromUserInterface.getPageContentAnimatedIn
  );

  const navMenu = useSelector(fromMainNavigation.getNavMenu);
  const navLogo = useSelector(fromMainNavigation.getNavLogo);
  const homeUrl = useSelector(fromMainNavigation.getHomeUrl);
  const dispatch = useDispatch();
  const enterNavMenuTriggerAction = useCallback(
    () =>
      dispatch(fromMainNavigation.actionCreators.enterNavMenuTriggerAction()),
    [dispatch]
  );
  const leaveNavMenuTriggerAction = useCallback(
    () =>
      dispatch(fromMainNavigation.actionCreators.leaveNavMenuTriggerAction()),
    [dispatch]
  );
  const showNavMenuAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.showNavMenuAction()),
    [dispatch]
  );
  const hideNavMenuAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.hideNavMenuAction()),
    [dispatch]
  );
  const preloadNavMenuAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.preloadNavMenuAction()),
    [dispatch]
  );
  const handleMainNavClickAction = useCallback(
    (linkId: number) =>
      dispatch(
        fromMainNavigation.actionCreators.handleMainNavClickAction(linkId)
      ),
    [dispatch]
  );
  const enterNavLogoAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.enterNavLogoAction()),
    [dispatch]
  );
  const leaveNavLogoAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.leaveNavLogoAction()),
    [dispatch]
  );

  useEffect(() => {
    if (animateContentIn) setAnimationReady(true);
  }, [animateContentIn]);

  const handleNavMenuTriggerMouseEnter = () => enterNavMenuTriggerAction();

  const handleNavMenuTriggerMouseLeave = () => leaveNavMenuTriggerAction();

  const [preloadNavMenuInterval, setPreloadNavMenuInterval] = useState(false);
  const latestNavMenuLoaded = useLatest(navMenu.loaded);
  useInterval(
    () => {
      if (latestNavMenuLoaded.current) {
        setPreloadNavMenuInterval(false);
        showNavMenuAction();
      }
    },
    preloadNavMenuInterval ? 10 : null
  );

  const triggerNavMenu = () => {
    if (navMenu.open) hideNavMenuAction();
    else if (navMenu.loaded) {
      showNavMenuAction();
    } else {
      preloadNavMenuAction();
      setPreloadNavMenuInterval(true);
    }
  };

  const springConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 250,
  };

  const [navMenuOpenSpring, setNavMenuOpenSpring] = useSpring(() => ({
    x: 0,
    config: springConfig,
  }));

  useEffect(() => {
    setNavMenuOpenSpring({ x: navMenu.open && animationReady ? 1 : 0 });
  }, [navMenu.open, animationReady]);

  const menuIconSpringConfig: SpringConfig = {
    ...springConfig,
    tension: 340,
    friction: 25,
  };

  const [menuIconSpring, setMenuIconSpring] = useSpring(() => ({
    x: 0,
    config: menuIconSpringConfig,
  }));

  useEffect(() => {
    setMenuIconSpring({
      x: (navMenu.open || navMenu.triggerHovering) && animationReady ? 1 : 0,
      immediate:
        (navMenu.open || navMenu.triggerHovering) && animationReady
          ? true
          : false,
    });
  }, [navMenu.open, animationReady, navMenu.triggerHovering]);

  const [navLogomarkSpring, setNavLogomarkSpring] = useSpring(() => ({
    x: 0,
    config: springConfig,
  }));

  useEffect(() => {
    setNavLogomarkSpring({ x: navLogo.markVisible && !navMenu.open ? 1 : 0 });
  }, [navLogo.markVisible, navMenu.open]);

  const logoStyles = {
    width: navLogomarkSpring.x.to((x) => `calc((1 - ${x}) * 82px)`),
    opacity: to([navLogomarkSpring.x, navMenuOpenSpring.x], (mark, menuOpen) =>
      navMenu.triggerHovering
        ? 1
        : navMenu.open
        ? 1
        : menuOpen !== 0
        ? menuOpen
        : (navMenu.open || navMenu.triggerHovering) && animationReady
        ? 0
        : 1 - mark
    ),
  };

  const logomarkStyles = {
    //transform: `translateX(calc((1 - ${navLogomark}) * (82px - 20px)))`,
    marginLeft: navLogomarkSpring.x.to(
      (x) => `calc((1 - ${x}) * (82px - 20px) - 3px)`
    ),
    //opacity: navLogomarkSpring.x,
    opacity: to([navLogomarkSpring.x, navMenuOpenSpring.x], (mark, menuOpen) =>
      navMenu.open
        ? (1 - menuOpen) * mark
        : menuOpen !== 0
        ? menuOpen * mark
        : navMenu.triggerHovering
        ? 1
        : (navMenu.open || navMenu.triggerHovering) && animationReady
        ? 0
        : mark
    ),
    display: to([navLogomarkSpring.x, navMenuOpenSpring.x], (mark, menuOpen) =>
      mark === 0 ? "none" : "block"
    ),
  };

  const menuIconTopLineStyles = {
    transform: navMenuOpenSpring.x.to((x) => `rotate(calc(${x} * 135deg))`),
    top: navMenuOpenSpring.x.to((x) => `calc(4px + (${x} * 7px))`),
  };

  const menuIconMiddleLineStyles = {
    transform: navMenuOpenSpring.x.to((x) => `rotate(calc(${x} * 90deg))`),
    opacity: navMenuOpenSpring.x.to((x) => 1 - x),
  };

  const menuIconBottomLineStyles = {
    transform: navMenuOpenSpring.x.to((x) => `rotate(calc(${x} * 45deg))`),
    top: navMenuOpenSpring.x.to((x) => `calc(18px - (${x} * 7px))`),
  };

  const sbw = useDisabledScrollWidth();

  const headerStyles = {
    // marginRight: sbw,
    marginRight: sbw.width.to((x) => x),
  };

  return (
    <a.div className="header" style={headerStyles}>
      <a.div
        className={classNames("nav-header", {
          active:
            navMenuOpenSpring.x.to((x) => (x > 0 ? 1 : 0)) ||
            navMenu.triggerHovering ||
            (navMenu.open && animationReady),
        })}
      >
        <BlockLink
          to={homeUrl}
          onClick={() => handleMainNavClickAction(1)}
          onMouseEnter={preloadNavMenuAction}
          title="Home"
        >
          <a.div
            className={classNames("logo", { navMenuOpen: navMenu.open })}
            style={logoStyles}
          >
            <Logo type="logotext" />
          </a.div>
          <a.div
            className={classNames(
              "logo",
              "overlay",
              { navMenuOpen: navMenu.open },
              { active: navLogo.hovering }
            )}
            style={{ ...logoStyles, opacity: null }}
            onMouseEnter={enterNavLogoAction}
            onMouseLeave={leaveNavLogoAction}
          >
            <Logo type="logotext" />
          </a.div>
          <a.div
            className={classNames("logomark", { navMenuOpen: navMenu.open })}
            style={logomarkStyles}
          >
            <Logo type="logomark" />
          </a.div>
          <a.div
            className={classNames(
              "logomark",
              "overlay",
              { navMenuOpen: navMenu.open },
              { active: navLogo.hovering }
            )}
            style={{ ...logomarkStyles, opacity: null }}
            onMouseEnter={enterNavLogoAction}
            onMouseLeave={leaveNavLogoAction}
          >
            <Logo type="logomark" />
          </a.div>
        </BlockLink>
        <a.div
          className={classNames("nav-menu-trigger", "button", {
            navMenuOpen: navMenu.open && animationReady,
          })}
        >
          <a.span style={menuIconTopLineStyles} />
          <a.span style={menuIconMiddleLineStyles} />
          <a.span style={menuIconBottomLineStyles} />
        </a.div>
        <a.div
          className={classNames(
            "nav-menu-trigger",
            "button",
            "overlay",
            // "active",
            { navMenuOpen: navMenu.open && animationReady }
          )}
          onMouseEnter={handleNavMenuTriggerMouseEnter}
          onClick={triggerNavMenu}
          onMouseLeave={handleNavMenuTriggerMouseLeave}
        >
          <a.span style={menuIconTopLineStyles} />
          <a.span style={menuIconMiddleLineStyles} />
          <a.span style={menuIconBottomLineStyles} />
        </a.div>
      </a.div>
    </a.div>
  );
};

export const HeaderMenu = HeaderMenuComponent;

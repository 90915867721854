import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
  BodyScrollOptions,
} from "body-scroll-lock";

import * as fromPageScroll from "store/PageScroll";

export const enablePullToRefresh = (allow = true, constant = false) => {
  const className = constant
    ? "disablePullToRefresh"
    : "disablePullToRefreshTemp";
  if (!allow) document.body.classList.add(className);
  else document.body.classList.remove(className);
};

export const DisableScroll: React.FC<{}> = () => {
  const pageScrollEnabled = useSelector(fromPageScroll.getEnabled);

  const interfaceRef = useRef<HTMLDivElement>(null);

  const options: BodyScrollOptions = {
    reserveScrollBarGap: true,
  };

  useEffect(() => {
    if (pageScrollEnabled) {
      enablePullToRefresh();
      enableBodyScroll(interfaceRef.current);
    } else {
      enablePullToRefresh(false);
      disableBodyScroll(interfaceRef.current, options);
    }
  }, [pageScrollEnabled]);

  return <div ref={interfaceRef} />;
};

import React, { useState, useEffect } from "react";
import { useSpring, SpringConfig } from "@react-spring/web";

export const useScrollTarget = (
  cancelAnimationOnManualScroll = true,
  onComplete: (restValues: object) => void = (restValues) => null,
  onStop: () => void = () => null
) => {
  const [target, setTarget] = useState(null);

  const springConfig: SpringConfig = {
    mass: 1,
    tension: 270,
    friction: 36,
    clamp: true,
    precision: 0.01,
    velocity: 0,
    //duration: undefined,
    easing: (t) => t,
  };

  const scrollRest = (restValues: object) => {
    if (onComplete != null) onComplete(restValues);
  };

  const [, targetSpringApi] = useSpring(() => ({
    y: -200,
    config: springConfig,
    onRest: (restValues) => scrollRest(restValues),
    default: true,
  }));

  useEffect(() => {
    if (target == null) {
      // targetSpringApi.stop(); // TODO: THIS NEEDS TO BE LOOKED AT AND FIXED TO WORK WITH REACT-SPRING 9.1.2
      if (onStop != null) onStop();
    } else
      targetSpringApi({
        y: target,
        reset: true,
        from: { y: window.scrollY },
        onChange: (result) => window.scroll(0, result.value.y as any),
      });
  }, [target]);

  useEffect(() => {
    if (cancelAnimationOnManualScroll) {
      // Setup event listeners on initial mount for cancelling scroll animations if the user is still scrolling
      const handleScroll = (event) => {
        setTarget(null);
      };

      window.addEventListener("wheel", handleScroll);

      return () => {
        window.removeEventListener("wheel", handleScroll);
      };
    }
  }, []);

  return setTarget;
};

//import 'babel-polyfill';
import { Reducer } from 'redux';
import { IApplicationState } from './index';

import * as fromPageScroll from 'store/PageScroll';

import { createSelector } from 'reselect';
import InPageNavigation from '../components/InterfaceOverlay/InPageNavigation/InPageNavigation';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface INavigation {
  title: string;
  scrollTop: number;
  height: number;
}

export interface IInPageNavigationState {
  scrollToSectionNumber: number;
  showSectionLabels: boolean;
  currentSectionNumber: number;
  hoverOverSectionNumber: number;
  totalSectionsNumber: number;
  navigationArray: INavigation[];
  visible: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export enum keys {
  SHOW_NAV = 'SHOW_NAV',
  HIDE_NAV = 'HIDE_NAV',
  SHOW_SECTION_LABELS = 'SHOW_SECTION_LABELS',
  HIDE_SECTION_LABELS = 'HIDE_SECTION_LABELS',
  SCROLL_TO_SECTION_NUMBER = 'SCROLL_TO_SECTION_NUMBER',
  UPDATE_HOVERING_SECTION_NUMBER = 'UPDATE_HOVERING_SECTION_NUMBER',
  UPDATE_TOTAL_SECTIONS_NUMBER = 'UPDATE_TOTAL_SECTIONS_NUMBER',
  UPDATE_NAVIGATION_ARRAY = 'UPDATE_NAVIGATION_ARRAY',
  UPDATE_NAVIGATION_BAR_ARRAY = 'UPDATE_NAVIGATION_BAR_ARRAY',
  SET_CURRENT_SECTION_NUMBER = 'SET_CURRENT_SECTION_NUMBER',
  DEFAULT_ACTION = 'DEFAULT_ACTION', // This is needed otherwise the reducer has a problem for some reason
}

interface IShowNav { type: keys.SHOW_NAV; }
interface IHideNav { type: keys.HIDE_NAV; }
interface IShowSectionsLabels { type: keys.SHOW_SECTION_LABELS; }
interface IHideSectionsLabels { type: keys.HIDE_SECTION_LABELS; }
interface IScrollToSectionNumberAction {
  type: keys.SCROLL_TO_SECTION_NUMBER;
  number: number;
}
interface IUpdateHoveringSectionNumberAction {
  type: keys.UPDATE_HOVERING_SECTION_NUMBER;
  number: number;
}
interface IUpdateTotalSectionsNumberAction {
  type: keys.UPDATE_TOTAL_SECTIONS_NUMBER;
  number: number;
}
interface IUpdateNavigationArrayAction {
  type: keys.UPDATE_NAVIGATION_ARRAY;
  navigationArray: INavigation[];
}
interface ISetCurrentSectionNumberAction {
  type: keys.SET_CURRENT_SECTION_NUMBER;
  number: number;
}
interface IDefaultAction { type: keys.DEFAULT_ACTION; }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type IKnownAction =
  IScrollToSectionNumberAction
  | IShowNav
  | IHideNav
  | IShowSectionsLabels
  | IHideSectionsLabels
  | IUpdateHoveringSectionNumberAction
  | IUpdateTotalSectionsNumberAction
  | IUpdateNavigationArrayAction
  | ISetCurrentSectionNumberAction
  | IDefaultAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
// We are using them to fire off our events that will be handled by the root sagas and delegated consequently

let hideSectionLabelTimeout;
let changeSectionHoverLabelTimeout;

export const actionCreators = {
  showNavAction: () => dispatch => dispatch({ type: keys.SHOW_NAV }),
  hideNavAction: () => dispatch => dispatch({ type: keys.HIDE_NAV }),
  showSectionLabelsAction: () => dispatch => {
    clearTimeout(hideSectionLabelTimeout);
    dispatch({ type: keys.SHOW_SECTION_LABELS });
  },
  hideSectionLabelsAction: () => dispatch => {
    hideSectionLabelTimeout = setTimeout(() => {
      dispatch({ type: keys.HIDE_SECTION_LABELS });
    }, 500);
  },
  scrollToSectionNumberAction: (section: number) => dispatch => {
    dispatch({
      type: keys.SCROLL_TO_SECTION_NUMBER,
      number: section,
    });
  },
  updateHoveringSectionNumberAction: (section: number) => dispatch => {
    clearTimeout(changeSectionHoverLabelTimeout);

    let timeoutDelay = 0;
    if (section === 0)
      timeoutDelay = 500;

    changeSectionHoverLabelTimeout = setTimeout(() => {
      dispatch({
        type: keys.UPDATE_HOVERING_SECTION_NUMBER,
        number: section,
      });
    }, timeoutDelay);
  },
  updateTotalSectionsNumberAction: (section: number) => dispatch => {
    dispatch({
      type: keys.UPDATE_TOTAL_SECTIONS_NUMBER,
      number: section,
    });
  },
  updateNavigationArrayAction: (navigationArray: INavigation[]) => dispatch => {
    dispatch({
      type: keys.UPDATE_NAVIGATION_ARRAY,
      navigationArray,
    });
  },
  setCurrentSectionNumberAction: (section: number) => dispatch => {
    dispatch({
      type: keys.SET_CURRENT_SECTION_NUMBER,
      number: section,
    });
  },
};

// ----------------
// SAGA WATCHERS - Register all saga watchers here that will intercept all dispatched calls and delegate appropriately

// ----------------
// SAGA WORKERS - These are saga worker functions that are called when receiving saga dispatches. The saga watchers
// intercept dispatched calls and call the relevant saga functions when appropriate

// ----------------
// ROOT SAGA - Register all saga watchers into one root saga to be initialised in configureStore

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IInPageNavigationState> = (state: IInPageNavigationState, action: IKnownAction) => {
  switch (action.type) {
    case keys.SHOW_NAV:
      return {
        ...state,
        visible: true,
      };
    case keys.HIDE_NAV:
      return {
        ...state,
        visible: false,
      };
    case keys.SHOW_SECTION_LABELS:
      return {
        ...state,
        showSectionLabels: true,
      };
    case keys.HIDE_SECTION_LABELS:
      return {
        ...state,
        showSectionLabels: false,
      };
    case keys.SCROLL_TO_SECTION_NUMBER:
      return {
        ...state,
        scrollToSectionNumber: action.number,
      };
    case keys.UPDATE_HOVERING_SECTION_NUMBER:
      return {
        ...state,
        hoverOverSectionNumber: action.number,
      };
    case keys.UPDATE_TOTAL_SECTIONS_NUMBER:
      return {
        ...state,
        totalSectionsNumber: action.number,
      };
    case keys.UPDATE_NAVIGATION_ARRAY:
      return {
        ...state,
        navigationArray: action.navigationArray,
      };
    case keys.SET_CURRENT_SECTION_NUMBER:
      return {
        ...state,
        currentSectionNumber: action.number,
      };
    case keys.DEFAULT_ACTION:
      return {
        ...state,
      };
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      const exhaustiveCheck: never = action;
  }

  // For unrecognized actions (or in cases where actions have no effect), must return the existing state
  //  (or default initial state if none was supplied)
  return state || {
    scrollToSectionNumber: -1,
    showSectionLabels: false,
    currentSectionNumber: 0,
    hoverOverSectionNumber: 0,
    totalSectionsNumber: 0,
    navigationArray: [],
    visible: false,
  };
};

// ----------------
// SELECTORS - These are functions exposed to UI components that will give them access to the associated store components.
// They only return the reference to the required state in the store, they don't change it.

const inPageNavigationSelector = (state: IApplicationState) => state.inPageNavigation;

const visibleSelector = (state: IApplicationState) => inPageNavigationSelector(state).visible;
export const getVisibility = createSelector( [visibleSelector], visible => visible );

const scrollToSectionNumberSelector = (state: IApplicationState) => inPageNavigationSelector(state).scrollToSectionNumber;
export const getScrollToSectionNumber = createSelector(
  [scrollToSectionNumberSelector],
  scrollToSectionNumber => {
    //console.log("Output selector running: getScrollToSectionNumber");
    return scrollToSectionNumber;
  },
);

const showSectionLabelsSelector = (state: IApplicationState) => inPageNavigationSelector(state).showSectionLabels;
export const getShowSectionLabels = createSelector(
  [showSectionLabelsSelector],
  showSectionLabels => {
    //console.log("Output selector running: getShowSectionLabels");
    return showSectionLabels;
  },
);

const currentSectionNumberSelector = (state: IApplicationState) => inPageNavigationSelector(state).currentSectionNumber;
export const getCurrentSectionNumber = createSelector(
  [currentSectionNumberSelector],
  currentSectionNumber => {
    //console.log("Output selector running: getCurrentSectionNumber");
    return currentSectionNumber;
  },
);

const hoverOverSectionNumberSelector = (state: IApplicationState) => inPageNavigationSelector(state).hoverOverSectionNumber;
export const getHoverOverSectionNumber = createSelector(
  [hoverOverSectionNumberSelector],
  hoverOverSectionNumber => {
    //console.log("Output selector running: getHoverOverSectionNumber");
    return hoverOverSectionNumber;
  },
);

const totalSectionsNumberSelector = (state: IApplicationState) => inPageNavigationSelector(state).totalSectionsNumber;
export const getTotalSectionsNumber = createSelector(
  [totalSectionsNumberSelector],
  totalSectionsNumber => {
    //console.log("Output selector running: getTotalSectionsNumber");
    return totalSectionsNumber;
  },
);

const navigationArraySelector = (state: IApplicationState) => inPageNavigationSelector(state).navigationArray;
export const getNavigationArray = createSelector(
  [navigationArraySelector],
  navigationArray => {
    //console.log("Output selector running: getNavigationArray");
    return navigationArray;
  },
);

import React from "react";

export type IIconTypes = "logotext" | "logomark";

interface IOwnSpecificProps {
  type: IIconTypes;
  color?: string;
}

type IOwnProps = IOwnSpecificProps;

type IProps = IOwnProps;

export const Logo: React.FC<IProps> = ({ type, color, ...props }) => {
  const svgProps = {
    className: "logoSvg",
    style: {
      fill: color,
    },
  };

  switch (type) {
    case "logotext":
      return (
        <svg viewBox="0 0 2904 1000" {...svgProps}>
          <path
            d="M452,0c9.4,1.3,18.89,2.11,28.17,3.98c75.25,15.2,121.27,61.46,141.78,134.39c6.13,21.78,8.6,44.13,8.61,66.73
          c0.04,155.31,0.09,310.63-0.05,465.94c-0.02,24.55-2.91,48.84-8.66,72.8c-11.06,46.08-38.31,79.04-80.24,100.36
          c-2.52,1.28-5.04,2.56-7.55,3.86c-0.28,0.14-0.49,0.41-1.15,0.98c32.26,42.68,64.54,85.38,97.48,128.96c-2.58,0-4.15,0-5.73,0
          c-53.49,0-106.99-0.04-160.48,0.09c-3.57,0.01-5.66-1.12-7.78-3.94c-22.91-30.51-46.01-60.86-68.89-91.38
          c-2.29-3.05-4.61-4.02-8.28-4.02c-62.82,0.06-125.65,0.34-188.47-0.2c-41.31-0.35-79.36-12.05-111.54-38.57
          c-40.94-33.74-68.49-75.93-76.73-129.27c-1.07-6.89-1.65-13.93-1.65-20.89C0.74,526.67,0.59,363.52,0.89,200.38
          c0.07-38.46,7.49-75.53,26.77-109.44c22.38-39.37,56.71-64.03,99.01-77.79c16.86-5.48,34.6-8.28,51.94-12.28
          c1.45-0.34,2.93-0.58,4.39-0.87C272.67,0,362.33,0,452,0z M214.56,654.17c1.85-0.09,2.79-0.17,3.72-0.17
          c54.5-0.01,109-0.04,163.49,0.13c2.02,0.01,4.7,1.5,5.96,3.16c20.94,27.4,41.76,54.9,62.42,82.5c2.43,3.24,4.2,3.56,7.65,1.56
          c13.51-7.84,23.61-18.86,31.17-32.34c10.15-18.1,13.13-37.81,13.12-58.26c-0.05-142.16,0.07-284.32-0.21-426.49
          c-0.02-11.53-1.43-23.35-4.19-34.54c-7.28-29.48-24.15-51.2-54.68-59.04c-11.31-2.91-23.34-4.1-35.05-4.15
          c-64.49-0.26-128.98-0.12-193.47,0.28c-39.11,0.25-70.33,22.81-82.12,58.42c-4.34,13.13-5.38,26.7-5.37,40.41
          c0,137.66-0.16,275.33,0.23,412.99c0.04,15.36,1.98,30.98,5.24,46c9.48,43.79,39.09,67.01,84.06,67.32
          c22,0.15,43.99,0.18,65.99,0.24c1.69,0,3.38-0.15,5.91-0.27C263.55,718.99,239.3,686.91,214.56,654.17z"
          />
          <path
            d="M2028,0c10.36,1.6,20.84,2.67,31.06,4.88c73.35,15.9,128.73,77.26,137.07,151.75c1.02,9.08,1.55,18.26,1.57,27.4
          c0.15,50.33-0.25,100.66,0.14,150.98c0.64,82.98-66.84,150.69-127.02,165.38c-10.89,2.66-22.31,4.24-33.51,4.4
          c-36.32,0.55-72.66,0.2-108.98,0.2c-1.83,0-3.66,0-6.75,0c103.31,136.54,206.07,272.36,308.83,408.18
          c-0.26,0.37-0.52,0.73-0.78,1.1c-9.11-1.58-18.23-3.14-27.34-4.74c-53.93-9.51-107.84-19.08-161.79-28.44
          c-4.78-0.83-7.99-2.76-10.92-6.65c-35.54-47.24-71.27-94.33-106.9-141.49c-56.43-74.7-112.84-149.41-169.15-224.19
          c-2.15-2.86-4.3-3.9-7.83-3.85c-15.33,0.2-30.66,0.09-45.99,0.09c-1.63,0-3.26,0-5.38,0c0,124.54,0,248.62,0,373.29
          c-2.18,0.15-3.92,0.37-5.66,0.37c-38.5,0.02-76.99-0.07-115.49,0.13c-4.77,0.02-5.73-1.52-5.73-5.95
          c0.1-289.3,0.08-578.6,0.1-867.9c0-1.65,0.28-3.29,0.43-4.94C1721.33,0,1874.67,0,2028,0z M1694.44,377.1c2.72,0,4.86,0,7.01,0
          c95.63,0,191.26-0.09,286.89,0.09c15.54,0.03,30.06-3.54,43.89-10.08c22.56-10.67,35.88-28.59,36.48-53.59
          c0.91-38.13,1.53-76.35-0.11-114.43c-2.18-50.87-35.88-71.59-75.78-72.23c-97.61-1.57-195.25-0.51-292.88-0.5
          c-1.77,0-3.55,0.16-5.5,0.25C1694.44,210.18,1694.44,293.25,1694.44,377.1z"
          />
          <path
            d="M1174,0c10.61,0.91,21.24,1.67,31.83,2.75c30.13,3.08,59.55,9.15,87.94,20.06c53.02,20.36,87.26,58.26,105.05,111.68
          c10.4,31.23,15.32,63.47,15.42,96.24c0.42,136.47,0.61,272.95,0.38,409.42c-0.06,36.76-4.88,73.01-17.16,107.98
          c-20.23,57.62-60.14,94.51-117.93,112.85c-28.55,9.06-57.8,15.06-87.65,15.86c-51.28,1.38-102.6,1.98-153.89,1.61
          c-40.35-0.29-79.89-6.84-117.38-22.66c-64.6-27.27-104.66-76.39-125.52-142.27c-8-25.26-11.54-51.29-11.55-77.74
          c-0.09-131.81-0.14-263.63,0.03-395.44c0.04-33.42,3.71-66.48,13.46-98.62c10.51-34.62,27.42-65.37,55.57-88.91
          c24.26-20.29,52.74-32.15,83.02-40.11c26.7-7.02,53.95-10.39,81.46-11.97c2.32-0.13,4.62-0.49,6.93-0.74C1074,0,1124,0,1174,0z
           M910.14,439.42c0,67.5-0.04,135,0.03,202.5c0.02,17.56,1.96,34.91,7.06,51.81c7.13,23.61,21.98,39.85,45.3,48.37
          c17.42,6.37,35.49,9.47,53.83,9.61c55.15,0.41,110.32,0.64,165.46-0.17c15.13-0.22,30.48-3.22,45.26-6.85
          c28.6-7.03,46.89-25.6,53.06-54.45c3.25-15.22,5.56-30.97,5.61-46.48c0.43-135.33,0.39-270.66,0.04-406
          c-0.04-14.89-1.95-29.95-4.67-44.62c-6.04-32.57-25.61-52.69-58.06-60.61c-20.44-4.99-41.15-6.05-61.96-6.1
          c-46.32-0.1-92.65,0.1-138.97,0.37c-18.72,0.11-37.16,2.52-55.11,8.16c-26.24,8.25-43.08,25.49-50.43,52.07
          c-4.53,16.35-6.41,33.01-6.42,49.9C910.11,304.42,910.14,371.92,910.14,439.42z"
          />
          <path
            d="M2904,126.33c-145.49,0.02-297.94,0.01-443.44,0.01c-2.13,0-4.26,0-6.72,0c0,82.39,0,164.27,0,246.68
          c149.67,0,300.36,0,450.16,0c0,42.92,0,85.32,0,128.42c-149.52,0-300.34,0-450,0c0,83.76,0,166.8,0,250.48c148.92,0,301.19,0,450,0
          c0,42.38,0,84.12,0,126.3c-190.86,0-385.57,0-577.14,0c0-1.76,0-3.52,0-5.28c0-289.49,0-578.98,0.01-868.47
          c0-1.5,0.09-2.99,0.14-4.49c192.33,0,384.67,0,577,0C2904,42,2904,123.97,2904,126.33z"
          />
        </svg>
      );
    case "logomark":
      return (
        <svg viewBox="0 0 563.28583402 1000" {...svgProps}>
          <polygon points="501.28583402,0 563.28583402,331 62,1000 0,669" />
        </svg>
      );
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      const exhaustiveCheck: never = type;
  }
};

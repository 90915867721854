import React, { useState, useEffect } from "react";
import { useSpring, a, to, SpringValue } from "@react-spring/web";

import classNames from "classnames";

interface IProps {
  sectionNumber: number;
  currentSection: boolean;
  expand: boolean;
  hovering: boolean;
  targetPosition: SpringValue;
  showLabel: boolean;
  label: string;
  updateHoveringSectionNumber(section: number): void;
  onClick(): void;
}

export const Bar: React.FC<IProps> = ({
  sectionNumber,
  currentSection,
  expand = false,
  hovering = false,
  targetPosition,
  showLabel = false,
  label,
  updateHoveringSectionNumber,
  onClick,
  ...props
}) => {
  const [initialised, setInitialised] = useState(false);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Interaction functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const handleMouseEnter = () => {
    updateHoveringSectionNumber(sectionNumber);
  };

  const handleMouseLeave = () => {
    updateHoveringSectionNumber(0);
  };

  const handleMouseClick = () => onClick();

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Set bar state functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [{ lbl, active }, setBarProps] = useSpring(() => ({
    lbl: 0,
    active: 0,
  }));

  useEffect(() => {
    setBarProps({
      lbl: showLabel ? 0.7 : 0,
      active: showLabel && (hovering || currentSection) ? 0.3 : 0,
      immediate: !initialised,
    });
    if (!initialised) setInitialised(true);
  }, [initialised, showLabel, hovering, currentSection]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Expand functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [{ y: expandY }, setExpand] = useSpring(() => ({ y: 0 }));

  useEffect(() => {
    setExpand({
      y: (showLabel && currentSection) || expand ? 1 : 0,
      immediate: !initialised,
    });
  }, [initialised, showLabel, expand]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Styles
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const wrapperStyles = {
    height: expandY.to((y) => `calc(30px + (${y} * 270px))`),
  };

  const barStyles = {
    transform: targetPosition.to(
      (p) => `translateY(calc(     (-100%) + (${p} * 100%)     ))`
    ),
  };

  const labelStyles = {
    opacity: to([lbl, active], (l, ac) => l + ac),
  };

  return (
    <a.div
      className="barWrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
      style={wrapperStyles}
    >
      <div className="bar">
        <div className="background" />
        <a.div
          className={classNames("progressBar", {
            enableShadow: targetPosition.get() > 0,
          })}
          style={barStyles}
        />
      </div>
      <a.div
        className={classNames("label", { activateLink: showLabel })}
        style={labelStyles}
      >
        <div className="text">{label}</div>
      </a.div>
    </a.div>
  );
};

import { useCallback } from 'react';
import { useSpring } from '@react-spring/web';

export const useInertialSpring = (initialProps): [any, ({ config, ...to }: any) => void] => {
  const [props, inertialSpringApi] = useSpring(() => initialProps);

  const setInertia = useCallback(
    ({
      config = { inertia: false },
      ...to
    }) => {
      const { inertia, bounds, velocity, ...rest } = config;
      if (inertia) {
        inertialSpringApi.start({
          ...to,
          onChange: values => {
            Object.entries(values.value).forEach(([key, v]) => {
              const vel = props[key].velocity;
              const bound =
                v >= bounds[key][1]
                  ? bounds[key][1]
                  : v <= bounds[key][0]
                    ? bounds[key][0]
                    : undefined;
              if (bound !== undefined) {
                props[key].stop();
                inertialSpringApi.start({ [key]: bound, config: { decay: false, velocity: vel } });
              }
            });
          },
          config: k => ({ decay: true, velocity: velocity[k] }),
        });
      }
      else {
        inertialSpringApi.start({ ...to, config: rest });
      }
    },
    [props, inertialSpringApi],
  );

  return [props, setInertia];
};

import { fetch, addTask } from 'domain-task';
import { Action, Reducer, ActionCreator } from 'redux';
import { IAppThunkAction } from './';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IWeatherForecastsState {
  isLoading: boolean;
  startDateIndex?: number;
  forecasts: IWeatherForecast[];
}

export interface IWeatherForecast {
  dateFormatted: string;
  temperatureC: number;
  temperatureF: number;
  summary: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface IRequestWeatherForecastsAction {
  type: 'REQUEST_WEATHER_FORECASTS';
  startDateIndex: number;
}

interface IReceiveWeatherForecastsAction {
  type: 'RECEIVE_WEATHER_FORECASTS';
  startDateIndex: number;
  forecasts: IWeatherForecast[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type IKnownAction = IRequestWeatherForecastsAction | IReceiveWeatherForecastsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  requestWeatherForecasts: (startDateIndex: number): IAppThunkAction<IKnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    if (startDateIndex !== getState().weatherForecasts.startDateIndex) {
      const fetchTask = fetch(`/api/SampleData/WeatherForecasts?startDateIndex=${startDateIndex}`)
        .then(response => response.json() as Promise<IWeatherForecast[]>)
        .then(data => {
          dispatch({ type: 'RECEIVE_WEATHER_FORECASTS', startDateIndex, forecasts: data });
        });

      addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
      dispatch({ type: 'REQUEST_WEATHER_FORECASTS', startDateIndex });
    }
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IWeatherForecastsState = { forecasts: [], isLoading: false };

export const reducer: Reducer<IWeatherForecastsState> = (state: IWeatherForecastsState, incomingAction: Action) => {
  const action = incomingAction as IKnownAction;
  switch (action.type) {
    case 'REQUEST_WEATHER_FORECASTS':
      return {
        ...state,
        startDateIndex: action.startDateIndex,
        forecasts: state.forecasts,
        isLoading: true,
      };
    case 'RECEIVE_WEATHER_FORECASTS':
      // Only accept the incoming data if it matches the most recent request. This ensures we correctly
      // handle out-of-order responses.
      if (action.startDateIndex === state.startDateIndex) {
        return {
          ...state,
          startDateIndex: action.startDateIndex,
          forecasts: action.forecasts,
          isLoading: false,
        };
      }
      break;
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      const exhaustiveCheck: never = action;
  }

  return state || unloadedState;
};

// ----------------
// SELECTORS - These are functions exposed to UI components that will give them access to the associated store components.
// They only return the reference to the required state in the store, they don't change it.

export const getWeatherForecast = state => state.weatherForecasts;

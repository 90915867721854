import React from "react";
import { useRouteMatch } from "react-router-dom";

import Loadable from "react-loadable";

import { SEOHelmet } from "components/SEO/SEOHelmet";

import LoadingScreen from "components/ScreenOverlays/LoadingScreen/LoadingScreen";
import InterfaceOverlay from "components/InterfaceOverlay/InterfaceOverlay";
//import PagePositionBar from 'components/InterfaceOverlay/PagePositionBar/PagePositionBar';
// import GridGuides from 'components/ScreenOverlays/GridGuides/GridGuides';
import Header from "components/Header/Header";
import CookieModal from "components/ScreenOverlays/CookieModal/CookieModal";
import { DisableScroll } from "components/DisableScroll/DisableScroll";
import { useViewportDimensions } from "hooks/useViewportDimensions";

import { HeaderMenu } from "components/InterfaceOverlay/HeaderMenu/HeaderMenu";
import { BackToWorkTrigger } from "components/InterfaceOverlay/InterfaceTrigger/Index";
import InPageNavigation from "components/InterfaceOverlay/InPageNavigation/InPageNavigation";

const ChatModal = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ChatModal" */ "components/InterfaceOverlay/ContactChatModal/ChatModal"
    ),
  loading: () => <div className="loadingNewComponent" />,
  delay: 200,
  //serverSideRequirePath: path.join(__dirname, 'components/InterfaceOverlay/ContactChatModal/ChatModal'),
  // modules: ["components/InterfaceOverlay/ContactChatModal/ChatModal"],
  // webpack: () => [
  //   require.resolveWeak(
  //     "components/InterfaceOverlay/ContactChatModal/ChatModal"
  //   ),
  // ],
});

export const Layout: React.FC<{}> = (props) => {
  useViewportDimensions();
  const workDetailsMatch = useRouteMatch({
    path: "/work/:projectName",
    strict: true,
    sensitive: true,
  });

  return (
    <div className="container-fluid">
      <SEOHelmet
        schema="WebPage"
        title="We create extraordinary experiences to help tell your brand's story"
        description="We create extraordinary experiences to help tell your brand’s story and are dedicated to crafting and delivering meaningful brands, products, and experiences that people love."
        contentType="product"
      />
      {/*<GridGuides />*/}
      {/*<PagePositionBar />*/}
      <LoadingScreen />
      <Header />
      <HeaderMenu />
      {workDetailsMatch && (
        <>
          <InPageNavigation key="inPageNav" />
          <BackToWorkTrigger key="backToWorkTrigger" />
        </>
      )}
      <ChatModal />
      <CookieModal />
      <DisableScroll />
      {props.children}
    </div>
  );
};

import { Reducer } from "redux";
import { IApplicationState } from "./index";

import { createSelector } from "reselect";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IViewportState {
  pageHeight: number;
  height: number;
  width: number;
  entranceOffsetScale: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export enum keys {
  UPDATE_PAGE_HEIGHT = "UPDATE_PAGE_HEIGHT",
  UPDATE_VIEWPORT_HEIGHT = "UPDATE_VIEWPORT_HEIGHT",
  UPDATE_VIEWPORT_WIDTH = "UPDATE_VIEWPORT_WIDTH",
  DEFAULT_ACTION = "DEFAULT_ACTION", // This is needed otherwise the reducer has a problem for some reason
}

interface IUpdatePageHeightAction {
  type: keys.UPDATE_PAGE_HEIGHT;
  height: number;
}
interface IUpdateViewportHeightAction {
  type: keys.UPDATE_VIEWPORT_HEIGHT;
  height: number;
}
interface IUpdateViewportWidthAction {
  type: keys.UPDATE_VIEWPORT_WIDTH;
  width: number;
  offsetScale: number;
}
interface IDefaultAction {
  type: keys.DEFAULT_ACTION;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type IKnownAction =
  | IUpdatePageHeightAction
  | IUpdateViewportHeightAction
  | IUpdateViewportWidthAction
  | IDefaultAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
// We are using them to fire off our events that will be handled by the root sagas and delegated consequently

export const actionCreators = {
  updatePageHeight: (height: number) => (dispatch) => {
    dispatch({ type: keys.UPDATE_PAGE_HEIGHT, height });
  },
  updateViewportHeight: (height: number) => (dispatch) => {
    dispatch({ type: keys.UPDATE_VIEWPORT_HEIGHT, height });
  },
  updateViewportWidth: (width: number) => (dispatch) => {
    let offsetScale: number;
    if (width >= 2560) offsetScale = 150 / 150;
    else if (width >= 1920) offsetScale = 130 / 150;
    else if (width >= 1280) offsetScale = 110 / 150;
    else if (width >= 1024) offsetScale = 100 / 150;
    else if (width >= 768) offsetScale = 80 / 150;
    else offsetScale = 40 / 150;

    dispatch({ type: keys.UPDATE_VIEWPORT_WIDTH, width, offsetScale });
  },
};

// ----------------
// SAGA WATCHERS - Register all saga watchers here that will intercept all dispatched calls and delegate appropriately

// ----------------
// SAGA WORKERS - These are saga worker functions that are called when receiving saga dispatches. The saga watchers
// intercept dispatched calls and call the relevant saga functions when appropriate

// ----------------
// ROOT SAGA - Register all saga watchers into one root saga to be initialised in configureStore

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IViewportState> = (
  state: IViewportState,
  action: IKnownAction
) => {
  switch (action.type) {
    case keys.UPDATE_VIEWPORT_HEIGHT:
      return {
        ...state,
        height: action.height,
      };
    case keys.UPDATE_VIEWPORT_WIDTH:
      return {
        ...state,
        width: action.width,
        entranceOffsetScale: action.offsetScale,
      };
    case keys.UPDATE_PAGE_HEIGHT:
      return {
        ...state,
        pageHeight: action.height,
      };
    case keys.DEFAULT_ACTION:
      return {
        ...state,
      };
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      const exhaustiveCheck: never = action;
  }

  // For unrecognized actions (or in cases where actions have no effect), must return the existing state
  //  (or default initial state if none was supplied)
  return (
    state || {
      pageHeight: null,
      height: null,
      width: null,
      entranceOffsetScale: 1,
    }
  );
};

// ----------------
// SELECTORS - These are functions exposed to UI components that will give them access to the associated store components.
// They only return the reference to the required state in the store, they don't change it.

const viewportSelector = (state: IApplicationState) => state.viewport;

export const getViewport = createSelector([viewportSelector], (viewport) => {
  //console.log("Output selector running: getViewport");
  return viewport;
});

const pageHeightSelector = (state: IApplicationState) =>
  viewportSelector(state).pageHeight;
export const getPageHeight = createSelector(
  [pageHeightSelector],
  (pageHeight) => {
    //console.log("Output selector running: getPageHeight");
    return pageHeight;
  }
);

const heightSelector = (state: IApplicationState) =>
  viewportSelector(state).height;
export const getHeight = createSelector([heightSelector], (height) => {
  //console.log("Output selector running: getHeight");
  return height;
});

const widthSelector = (state: IApplicationState) =>
  viewportSelector(state).width;
export const getWidth = createSelector([widthSelector], (width) => {
  //console.log("Output selector running: getWidth");
  return width;
});

const entranceOffsetScaleSelector = (state: IApplicationState) =>
  viewportSelector(state).entranceOffsetScale;
export const getEntranceOffsetScale = createSelector(
  [entranceOffsetScaleSelector],
  (entranceOffsetScale) => {
    console.log("Output selector running: getEntranceOffsetScale");
    return entranceOffsetScale;
  }
);

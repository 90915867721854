//import 'babel-polyfill';
import { Action, Reducer } from "redux";
import { IAppThunkAction } from "./";
import * as api from "api/api";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ICounterState {
  count: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface IIncrementCountAction {
  type: "INCREMENT_COUNT";
}
interface IDecrementCountAction {
  type: "DECREMENT_COUNT";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type IKnownAction = IIncrementCountAction | IDecrementCountAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
// We are using them to fire off our events that will be handled by the root sagas and delegated consequently

export const moreActionCreators = {
  hello: () => ({ type: "DECREMENT_COUNT" } as IDecrementCountAction),
};

export const actionCreators = {
  fetchServerDetails: () => (dispatch) => {
    dispatch({ type: "FETCH_SERVER_DETAILS" });
  },
  increment: () => ({ type: "INCREMENT_COUNT" } as IIncrementCountAction),
  decrement: () => ({ type: "DECREMENT_COUNT" } as IDecrementCountAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ICounterState> = (
  state: ICounterState,
  action: IKnownAction
) => {
  switch (action.type) {
    case "INCREMENT_COUNT":
      return {
        ...state,
        count: state.count + 100,
      };
    case "DECREMENT_COUNT":
      return {
        ...state,
        count: state.count - 50,
      };
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      const exhaustiveCheck: never = action;
  }

  // For unrecognized actions (or in cases where actions have no effect), must return the existing state
  //  (or default initial state if none was supplied)
  return state || { count: 0 };
};

// ----------------
// SELECTORS - These are functions exposed to UI components that will give them access to the associated store components.
// They only return the reference to the required state in the store, they don't change it.

export const getCounter = (state) => state.counter;

import _BlockLink from './BlockLink/BlockLink';
export const BlockLink = _BlockLink;

export { ButtonLink, IOwnSpecificProps as IButtonLinkProps} from './ButtonLink/ButtonLink';

import _SimpleLink from './SimpleLink/SimpleLink';
export const SimpleLink = _SimpleLink;

import _TriggerLink from './TriggerLink/TriggerLink';
export const TriggerLink = _TriggerLink;

//import 'babel-polyfill';
import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
  Store,
  StoreEnhancerStoreCreator,
  ReducersMapObject,
} from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import * as StoreModule from "./store";
import { IApplicationState, createRootReducer, reducers } from "./store";
import { History } from "history";

import { signalRMiddleware } from "store/middleware/signalR";

function logger({ getState }) {
  return (next) => (action) => {
    //console.log('will dispatch', action)

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);

    //console.log('state after dispatch', getState())

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
}

export default function configureStore(
  history: History,
  preloadedState?: IApplicationState,
  context = {}
) {
  const middleware = [
    thunk,
    logger,
    routerMiddleware(history),
    signalRMiddleware,
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = true;
  if (
    isDevelopment &&
    typeof window !== "undefined" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
  ) {
    enhancers.push((window as any).__REDUX_DEVTOOLS_EXTENSION__());
    //enhancers.push((window as any).devToolsExtension());
  }

  const createStoreWithMiddleware = compose(
    applyMiddleware(...middleware),
    ...enhancers
  )(createStore);

  // Combine all reducers and instantiate the app-wide store instance
  //const allReducers = createRootReducer(history);
  const allReducers = createRootReducer(history, reducers);
  const store = createStoreWithMiddleware(
    allReducers,
    preloadedState
  ) as Store<IApplicationState>;

  // Enable Webpack hot module replacement for reducers
  if (module.hot) {
    module.hot.accept("./store", () => {
      const nextRootReducer = require<typeof StoreModule>("./store");
      //store.replaceReducer(createRootReducer(history));
      store.replaceReducer(
        createRootReducer(history, nextRootReducer.reducers)
      );
    });
  }

  return store;
}

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useSpring, a, SpringConfig } from "@react-spring/web";
import { defaultSpringConfig } from "components/Animations/SpringProperties/SpringProperties";

import { LogomarkSlide } from "./LogomarkSlide";

import * as fromUserInterface from "store/UserInterface";

import { useTimeout, useInterval, useLatest } from "utils/Index";

interface IOwnSpecificProps {
  finalBackgroundImage: string;
  endContentAnimation: boolean;
  endedContentAnimation(): any;
  hideLoadingScreen(): any;
}

type IProps = IOwnSpecificProps;

interface ILogomarkSlide {
  backgroundImage: string;
  persist: boolean;
  animateOut: boolean;
}

const Content: React.FC<IProps> = ({
  finalBackgroundImage,
  endContentAnimation,
  ...props
}) => {
  const interfaceInitialised = useSelector(fromUserInterface.getInitialised);

  const [logomarkSlides, setLogomarkSlides] = useState<ILogomarkSlide[]>([]);
  const [transitionEnd, setTransitionEnd] = useState(false);
  const [animationDelay] = useState(250);
  const [slideInDelay] = useState(250);
  const [slideOutDelay] = useState(0);
  const [screenAnimateOutDelay] = useState(500);
  const [backgroundImages, setBackgroundImages] = useState<string[]>([]);
  const [animateIn, setAnimateIn] = useState(false);

  useEffect(() => {
    if (backgroundImages.length === 0) {
      const newBackgroundImages: string[] = [];
      //console.log(this.props.projectContentBackgrounds);
      newBackgroundImages.push("/assets/images/logomarkBackground.jpg");
      setBackgroundImages(newBackgroundImages);
    }
  }, [backgroundImages]);

  useEffect(() => {
    startContentAnimation();
  }, []);

  const latestEndContentAnimation = useLatest(endContentAnimation);
  const latestLogomarkSlides = useLatest(logomarkSlides);
  const latestBackgroundImages = useLatest(backgroundImages);
  const latestFinalBackgroundImage = useLatest(finalBackgroundImage);

  const [triggerSlideInterval, setTriggerSlideInterval] = useState(false);
  useInterval(
    () => {
      if (latestEndContentAnimation.current) {
        triggerEndOfContentAnimation();
      } else {
        triggerNextSlide();
      }
    },
    triggerSlideInterval ? animationDelay : null
  );

  const [triggerSlideTimeout, setTriggerSlideTimeout] = useState(false);
  useTimeout(
    () => {
      setTriggerSlideTimeout(false);
      triggerNextSlide();
      setTriggerSlideInterval(true);
    },
    triggerSlideTimeout ? slideInDelay : null
  );

  const startContentAnimation = () => {
    setTransitionEnd(false);
    triggerNextSlide();
    setTriggerSlideTimeout(true);
  };

  const generateUniqueSlideIndex = () => {
    const localBackgroundImages: string[] =
      latestBackgroundImages.current.filter(
        (image) =>
          image !==
          latestLogomarkSlides.current[latestLogomarkSlides.current.length - 1]
            .backgroundImage
      );

    const newSlideIndex: number = Math.floor(
      Math.random() * localBackgroundImages.length
    );
    const newBackgroundImage = localBackgroundImages[newSlideIndex];
    return newBackgroundImage;
  };

  const triggerNextSlide = (
    backgroundImage: string = null,
    persistSlide: boolean = false
  ) => {
    const newLogomarkSlides = [...latestLogomarkSlides.current];

    const previousSlideIndex = newLogomarkSlides.length - 1;

    if (previousSlideIndex > -1)
      newLogomarkSlides[previousSlideIndex].animateOut = true;

    let newBackgroundImage: string = "";

    if (backgroundImage === "start") newBackgroundImage = null;
    else {
      if (backgroundImage != null) {
        newBackgroundImage = "/assets/images/" + backgroundImage;
      } else newBackgroundImage = generateUniqueSlideIndex();
    }

    newLogomarkSlides.push({
      backgroundImage: newBackgroundImage,
      persist: persistSlide,
      animateOut: false,
    });

    setLogomarkSlides(newLogomarkSlides);
    setAnimateIn(true);
  };

  const [triggerHideLoadingScreenTimeout, setTriggerHideLoadingScreenTimeout] =
    useState(false);
  useTimeout(
    () => {
      setTriggerHideLoadingScreenTimeout(false);
      props.hideLoadingScreen();
    },
    triggerHideLoadingScreenTimeout ? screenAnimateOutDelay : null
  );

  const [triggerSlideEndTimeout, setTriggerSlideEndTimeout] = useState(false);
  useTimeout(
    () => {
      setTriggerSlideEndTimeout(false);
      setTransitionEnd(true);
      setTriggerHideLoadingScreenTimeout(true);
    },
    triggerSlideEndTimeout ? slideOutDelay : null
  );

  const triggerEndOfContentAnimation = () => {
    setTriggerSlideInterval(false);
    triggerNextSlide(latestFinalBackgroundImage.current, true);
    setTriggerSlideEndTimeout(true);
  };

  const signalEndOfContentAnimation = () => {
    props.endedContentAnimation();
    setAnimateIn(false);
  };

  const animateInSpringConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 340,
    friction: 57,
  };

  const [animateInSpring, setAnimateInSpring] = useSpring(() => ({
    y: 0,
    immediate: false,
    config: animateInSpringConfig,
  }));

  useEffect(() => {
    setAnimateInSpring({
      y: animateIn ? 100 : 0,
      immediate: !interfaceInitialised ? true : animateIn ? false : true,
    });
  }, [animateIn]);

  const animateOutSpringConfig: SpringConfig = {
    ...animateInSpringConfig,
    tension: 250,
  };

  const [animateOutSpring, setAnimateOutSpring] = useSpring(() => ({
    y: 100,
    config: animateOutSpringConfig,
    // onChange: {
    //   y: value => onChange(value),
    // },
    onRest: {
      y: (value) => onRest(value),
    },
  }));

  const onChange = (value) => {
    if (value >= 0 && value < 0.000001) signalEndOfContentAnimation();
  };

  const onRest = (value) => {
    if (value === 0) signalEndOfContentAnimation();
  };

  useEffect(() => {
    setAnimateOutSpring({ y: transitionEnd ? 0 : 100 });
    setOpacitySpring({ opacity: transitionEnd ? 0 : 1 });
  }, [transitionEnd]);

  const [opacitySpring, setOpacitySpring] = useSpring(() => ({
    opacity: 1,
    config: animateOutSpringConfig,
  }));

  const logomarkContainerStyles = {
    opacity: opacitySpring.opacity,
    height: animateOutSpring.y.to((y) => `${y}%`),
    willChange: `height`,
  };

  const imageStyles = {
    height: animateInSpring.y.to((y) => `${y}%`),
    top: transitionEnd ? 0 : "auto",
    bottom: transitionEnd ? "auto" : 0,
    backgroundPosition: transitionEnd ? "top center" : "bottom center",
    willChange: `height`,
  };

  const logomarkSlidesComponents = logomarkSlides.map(
    (logomarkSlide, index) => {
      return (
        <LogomarkSlide
          key={index}
          backgroundImage={logomarkSlide.backgroundImage}
          persist={logomarkSlide.persist}
          animateOut={logomarkSlide.animateOut}
        />
      );
    }
  );

  return (
    <a.div className="contentContainer" style={logomarkContainerStyles}>
      <div className="logomark">
        {logomarkSlidesComponents}
        <a.div className="backgroundOverlay simpleImage" style={imageStyles} />
      </div>
    </a.div>
  );
};

export default Content;

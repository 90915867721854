import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import Loadable from "react-loadable";

import { useSpring, a, SpringConfig } from "@react-spring/web";
import { defaultSpringConfig } from "components/Animations/SpringProperties/SpringProperties";

import * as Waypoint from "react-waypoint";

import * as fromMainNavigation from "store/MainNavigation";
import * as fromInterfaceOverlay from "store/InterfaceOverlay";

import { useInterval, useLatest, usePrevious } from "utils/Index";
import { useDisabledScrollWidth } from "hooks/useDisabledScrollWidth";

import NavMenu from "./NavMenu/NavMenu";

//const NavMenu = Loadable({
//  loader: () => import(/* webpackChunkName: "NavMenu" */ './NavMenu/NavMenu'),
//  loading: () => <div className='loadingNewComponent' />,
//  delay: 200,
//  //serverSideRequirePath: path.join(__dirname, 'components/Menus/NavMenu/NavMenu'),
// //  modules: ['./NavMenu/NavMenu'],
// //  webpack: () => [require.resolveWeak('./NavMenu/NavMenu')],
//});

const Header: React.FC<{}> = () => {
  const [animationReady, setAnimationReady] = useState(false);

  const navMenu = useSelector(fromMainNavigation.getNavMenu);
  const chatWindowOpen = useSelector(fromInterfaceOverlay.getChatModalWindow);
  const dispatch = useDispatch();
  const enterNavMenuTriggerAction = useCallback(
    () =>
      dispatch(fromMainNavigation.actionCreators.enterNavMenuTriggerAction()),
    [dispatch]
  );
  const leaveNavMenuTriggerAction = useCallback(
    () =>
      dispatch(fromMainNavigation.actionCreators.leaveNavMenuTriggerAction()),
    [dispatch]
  );
  const showNavMenuAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.showNavMenuAction()),
    [dispatch]
  );
  const peekAtNavMenuAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.peekAtNavMenuAction()),
    [dispatch]
  );
  const unpeekAtNavMenuAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.unpeekAtNavMenuAction()),
    [dispatch]
  );
  const hideNavMenuAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.hideNavMenuAction()),
    [dispatch]
  );
  const preloadNavMenuAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.preloadNavMenuAction()),
    [dispatch]
  );
  const hideNavLogomarkAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.hideNavLogomarkAction()),
    [dispatch]
  );
  const showNavLogomarkAction = useCallback(
    () => dispatch(fromMainNavigation.actionCreators.showNavLogomarkAction()),
    [dispatch]
  );

  useEffect(() => {
    setAnimationReady(true);
  }, []);

  const previousNavMenuTriggerHovering = usePrevious(navMenu.triggerHovering);

  useEffect(() => {
    //if (!navMenu.loaded && navMenu.load)
    //  preloadNavMenu();

    if (!previousNavMenuTriggerHovering && navMenu.triggerHovering)
      peekAtNavMenu();
    else if (previousNavMenuTriggerHovering && !navMenu.triggerHovering)
      stopPeekingAtNavMenu();
  }, [navMenu.loaded, navMenu.load, navMenu.triggerHovering]);

  //const preloadNavMenu = () => {
  //  NavMenu.preload();
  //};

  const handleNavMenuTriggerMouseEnter = () => {
    enterNavMenuTriggerAction();
  };

  const handleNavMenuTriggerMouseLeave = () => {
    leaveNavMenuTriggerAction();
  };

  const [delay] = useState(10);
  const [triggerOpenMenuInterval, setTriggerOpenMenuInterval] = useState(false);
  const [triggerPeekMenuInterval, setTriggerPeekMenuInterval] = useState(false);

  const latestNavMenuLoaded = useLatest(navMenu.loaded);

  useInterval(
    () => {
      if (latestNavMenuLoaded.current) {
        setTriggerOpenMenuInterval(false);
        showNavMenuAction();
      }
    },
    triggerOpenMenuInterval ? delay : null
  );

  useInterval(
    () => {
      if (latestNavMenuLoaded.current) {
        setTriggerPeekMenuInterval(false);
        peekAtNavMenuAction();
      }
    },
    triggerPeekMenuInterval ? delay : null
  );

  const triggerNavMenu = () => {
    if (navMenu.open) hideNavMenuAction();
    else if (navMenu.loaded) {
      showNavMenuAction();
    } else {
      preloadNavMenuAction();
      setTriggerOpenMenuInterval(true);
    }
  };

  const peekAtNavMenu = () => {
    if (!navMenu.open) {
      if (navMenu.loaded) {
        peekAtNavMenuAction();
      } else {
        preloadNavMenuAction();
        setTriggerPeekMenuInterval(true);
      }
    }
  };

  const stopPeekingAtNavMenu = () => {
    setTriggerPeekMenuInterval(false);
    setTriggerOpenMenuInterval(false);
    if (navMenu.peek && !navMenu.open) unpeekAtNavMenuAction();
  };

  const handleLogomarkWaypointEnter = () => {
    hideNavLogomarkAction();
  };

  const handleLogomarkWaypointLeave = () => {
    showNavLogomarkAction();
  };

  const springConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 250,
  };
  const [openNavMenuSpring, setOpenNavMenuSpring] = useSpring(() => ({
    open: 0,
    config: springConfig,
  }));

  useEffect(() => {
    setOpenNavMenuSpring({
      open: (navMenu.open && animationReady) || chatWindowOpen ? 1 : 0,
    });
  }, [navMenu.open, animationReady, chatWindowOpen]);

  const sbw = useDisabledScrollWidth();

  const triggerPaneStyles = {
    transform: openNavMenuSpring.open.to(
      (x) => `translateY(calc(${x} * -40px))`
    ),
    // right: sbw,
    right: sbw.width.to((x) => x),
  };

  return (
    <Fragment>
      <div className="headerWaypoint">
        <Waypoint
          onEnter={handleLogomarkWaypointEnter}
          onLeave={handleLogomarkWaypointLeave}
          fireOnRapidScroll={true}
          topOffset={0}
          bottomOffset={-1000}
        />
      </div>
      <div className="header">
        <a.div
          className="nav-menu-trigger pane"
          onMouseEnter={handleNavMenuTriggerMouseEnter}
          onClick={triggerNavMenu}
          onMouseLeave={handleNavMenuTriggerMouseLeave}
          style={triggerPaneStyles}
        />
      </div>
      <NavMenu />
    </Fragment>
  );
};

export default Header;

//{ (navMenu.loaded ? <NavMenu /> : null) }

import * as React from 'react';
//import { slackErrorBot } from 'integrations/slack';

import { websiteReport } from 'api/api';

interface IState {
  error: string;
  errorInfo: string;
}

export class ErrorBoundary extends React.Component<{}, IState> {
  public state = {
    error: null,
    errorInfo: null,
  };

  public componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });

    websiteReport({
      text: JSON.stringify(errorInfo),
      fields: [
        {
          title: "url",
          value: window.location.href,
          short: false,
        },
        {
          title: "agent",
          value: window.navigator.userAgent,
          short: false,
        },
        {
          title: "error",
          value: error.toString(),
          short: false,
        },
        {
          title: "error info",
          value: JSON.stringify(errorInfo),
          short: false,
        },
        {
          title: "component stack",
          value: errorInfo.componentStack,
          short: false,
        },
      ],
    });
    // You can also log error messages to an error reporting service here
  }

  public render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

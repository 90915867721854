import * as fromInterfaceOverlay from 'store/InterfaceOverlay';
import * as signalR from "@microsoft/signalr";

export enum keys {
  SIGNALR_LINK_CHATID = 'SIGNALR_LINK_CHATID',
  SIGNALR_START_CHAT = 'SIGNALR_START_CHAT',
  SIGNALR_ADD_CHAT_MESSAGE = 'SIGNALR_ADD_CHAT_MESSAGE',
  SIGNALR_ADD_FORM_VALUE = 'SIGNALR_ADD_FORM_VALUE',
  SIGNALR_RESET_FORM_VALUES = 'SIGNALR_RESET_FORM_VALUES',
  SIGNALR_UPDATE_CHAT_CURRENTSCRIPTSTEPID = 'SIGNALR_UPDATE_CHAT_CURRENTSCRIPTSTEPID',
  SIGNALR_UPDATE_CHAT_NEXTSCRIPTSTEPID = 'SIGNALR_UPDATE_CHAT_NEXTSCRIPTSTEPID',
  SIGNALR_UPDATE_CHAT_CURRENTUSERINPUTID = 'SIGNALR_UPDATE_CHAT_CURRENTUSERINPUTID',
  SIGNALR_UPDATE_CHAT_USERINPUTVISIBILITY = 'SIGNALR_UPDATE_CHAT_USERINPUTVISIBILITY',
}

interface ISignalRAction { signalR: boolean; }
interface ILinkChatIdAction extends ISignalRAction { type: keys.SIGNALR_LINK_CHATID; id: string; }
interface IStartChatAction extends ISignalRAction { type: keys.SIGNALR_START_CHAT; }
interface IAddChatMessageAction extends ISignalRAction { type: keys.SIGNALR_ADD_CHAT_MESSAGE; message: fromInterfaceOverlay.IMessage; }
interface IAddFormValueAction extends ISignalRAction { type: keys.SIGNALR_ADD_FORM_VALUE; formValue: fromInterfaceOverlay.IFormValue; }
interface IResetFormValuesAction extends ISignalRAction { type: keys.SIGNALR_RESET_FORM_VALUES; }
interface IUpdateChatCurrentScriptStepIdAction extends ISignalRAction { type: keys.SIGNALR_UPDATE_CHAT_CURRENTSCRIPTSTEPID; id: string; }
interface IUpdateChatNextScriptStepIdAction extends ISignalRAction { type: keys.SIGNALR_UPDATE_CHAT_NEXTSCRIPTSTEPID; id: string; }
interface IUpdateChatCurrentUserInputIdAction extends ISignalRAction { type: keys.SIGNALR_UPDATE_CHAT_CURRENTUSERINPUTID; id: string; }
interface IUpdateChatUserInputVisibilityAction extends ISignalRAction { type: keys.SIGNALR_UPDATE_CHAT_USERINPUTVISIBILITY; visible: boolean; }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  ILinkChatIdAction
  | IStartChatAction
  | IAddChatMessageAction
  | IAddFormValueAction
  | IResetFormValuesAction
  | IUpdateChatCurrentScriptStepIdAction
  | IUpdateChatNextScriptStepIdAction
  | IUpdateChatCurrentUserInputIdAction
  | IUpdateChatUserInputVisibilityAction;

export const actionCreators = {
  linkWithChatId: (id: string) => dispatch => {
    dispatch({
      type: keys.SIGNALR_LINK_CHATID,
      id,
      signalR: true,
    });
  },
  startChat: () => dispatch => {
    dispatch({
      type: keys.SIGNALR_START_CHAT,
      signalR: true,
    });
  },
  addChatMessage: (message: fromInterfaceOverlay.IMessage) => dispatch => {
    dispatch({
      type: keys.SIGNALR_ADD_CHAT_MESSAGE,
      message,
      signalR: true,
    });
  },
  addFormValue: (formValue: fromInterfaceOverlay.IFormValue) => dispatch => {
    dispatch({
      type: keys.SIGNALR_ADD_FORM_VALUE,
      formValue,
      signalR: true,
    });
  },
  resetFormValues: () => dispatch => {
    dispatch({
      type: keys.SIGNALR_RESET_FORM_VALUES,
      signalR: true,
    });
  },
  updateCurrentScriptStepId: (id: string) => dispatch => {
    dispatch({
      type: keys.SIGNALR_UPDATE_CHAT_CURRENTSCRIPTSTEPID,
      id,
      signalR: true,
    });
  },
  updateNextScriptStepId: (id: string) => dispatch => {
    dispatch({
      type: keys.SIGNALR_UPDATE_CHAT_NEXTSCRIPTSTEPID,
      id,
      signalR: true,
    });
  },
  updateCurrentUserInputId: (id: string) => dispatch => {
    dispatch({
      type: keys.SIGNALR_UPDATE_CHAT_CURRENTUSERINPUTID,
      id,
      signalR: true,
    });
  },
  updateUserInputVisibility: (visible: boolean) => dispatch => {
    dispatch({
      type: keys.SIGNALR_UPDATE_CHAT_USERINPUTVISIBILITY,
      visible,
      signalR: true,
    });
  },
};

let chatHub = null;

export const signalRMiddleware = ({ dispatch, getState }) => {
  return next => async action => {
    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);

    //------------------------------------------------------------------------------ Create SignalR hub connection

    if (typeof window !== 'undefined' && chatHub == null) {
      // Declare a reference to the hub.
      chatHub = new signalR.HubConnectionBuilder()
        .withUrl("/chatHub")
        .withAutomaticReconnect()
        //.AddNewtonsoftJsonProtocol()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      //console.log('signalr connection state', chatHub.state, signalR.HubConnectionState.Connected, signalR.HubConnectionState.Disconnected);

      if (chatHub.state === signalR.HubConnectionState.Disconnected) {

        // -------------------------------------------------------------------- Chat Hub Functions

        chatHub.on("startChat", () => {
          dispatch(fromInterfaceOverlay.actionCreators.markChatAsStarted(false));
        });

        chatHub.on("receiveMessage", (message: fromInterfaceOverlay.IMessage) => {
          dispatch(fromInterfaceOverlay.actionCreators.receiveChatMessage(message, false));
        });

        chatHub.on("receiveFormValue", (formValue: fromInterfaceOverlay.IFormValue) => {
          dispatch(fromInterfaceOverlay.actionCreators.receiveFormValue(formValue, false));
        });

        chatHub.on("resetFormValues", () => {
          dispatch(fromInterfaceOverlay.actionCreators.receiveResetFormValues(false));
        });

        chatHub.on("receiveCurrentScriptStepId", (id: string) => {
          dispatch(fromInterfaceOverlay.actionCreators.receiveCurrentScriptStepId(id, false));
        });

        chatHub.on("receiveNextScriptStepId", (id: string) => {
          dispatch(fromInterfaceOverlay.actionCreators.receiveNextScriptStepId(id, false));
        });

        chatHub.on("receiveCurrentUserInputId", (id: string) => {
          dispatch(fromInterfaceOverlay.actionCreators.receiveCurrentUserInputId(id, false));
        });

        chatHub.on("receiveUserInputVisibility", (visible: boolean) => {
          dispatch(fromInterfaceOverlay.actionCreators.receiveUserInputVisibility(visible, false));
        });

        // -------------------------------------------------------------------- General Hub Functions

        async function start(connection) {
          try {
            await connection.start()
              .then(() => {
                const chatId = fromInterfaceOverlay.getChatModalId(getState());
                dispatch(actionCreators.linkWithChatId(chatId));
                console.info('SignalR Connected');
              })
              .catch(err => console.error('SignalR Connection Error: ', err));
            console.assert(connection.state === signalR.HubConnectionState.Connected);
          } catch (err) {
            console.assert(connection.state === signalR.HubConnectionState.Disconnected);
            console.log(err);
            setTimeout(() => start(connection), 5000);
          }
        }

        chatHub.onclose(async () => {
          await start(chatHub);
        });

        start(chatHub);
      }

      //------------------------------------------------------------------------------ End SignalR Code
    }

    if (action.signalR && chatHub != null) {
      if (chatHub.state === signalR.HubConnectionState.Connected
        //|| chatHub.state === signalR.HubConnectionState.Connecting
        //|| chatHub.state === signalR.HubConnectionState.Reconnecting
      ) {

        const chatId = fromInterfaceOverlay.getChatModalId(getState());

        const handleSignalRActionsReducer = (signalRAction: KnownAction) => {
          switch (signalRAction.type) {
            case keys.SIGNALR_LINK_CHATID:
              chatHub.invoke("LinkWithChatId", signalRAction.id);
              break;
            case keys.SIGNALR_START_CHAT:
              chatHub.invoke("StartChat", chatId);
              break;
            case keys.SIGNALR_ADD_CHAT_MESSAGE:
              chatHub.invoke("NewMessage", chatId, signalRAction.message);
              break;
            case keys.SIGNALR_ADD_FORM_VALUE:
              chatHub.invoke("NewFormValue", chatId, signalRAction.formValue);
              break;
            case keys.SIGNALR_RESET_FORM_VALUES:
              chatHub.invoke("ResetFormValues", chatId);
              break;
            case keys.SIGNALR_UPDATE_CHAT_CURRENTSCRIPTSTEPID:
              chatHub.invoke("UpdateCurrentScriptStepId", chatId, signalRAction.id);
              break;
            case keys.SIGNALR_UPDATE_CHAT_NEXTSCRIPTSTEPID:
              chatHub.invoke("UpdateNextScriptStepId", chatId, signalRAction.id);
              break;
            case keys.SIGNALR_UPDATE_CHAT_CURRENTUSERINPUTID:
              chatHub.invoke("UpdateCurrentUserInputId", chatId, signalRAction.id);
              break;
            case keys.SIGNALR_UPDATE_CHAT_USERINPUTVISIBILITY:
              chatHub.invoke("UpdateUserInputVisibility", chatId, signalRAction.visible);
              //.then(() => console.log('signalr chat user input visibility successfully updated'));
              break;
            default:
              // The following line guarantees that every action in the KnownAction union has been covered by a case above
              const exhaustiveCheck: never = signalRAction;
          }
        };

        handleSignalRActionsReducer(action);
      }
    }

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
};

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSpring } from '@react-spring/web';

import * as fromPageScroll from 'store/PageScroll';
import { scrollbarWidth } from '@xobotyi/scrollbar-width';

export function useDisabledScrollWidth() {
  // const pageScrollEnabled = useSelector(fromPageScroll.getEnabled);

  const [width, setWidth] = useSpring(() => ({ width: 0, immediate: true }));
  // // const [width, setWidth] = useState(0);
  // useEffect(() => {
  //   // setWidth(pageScrollEnabled ? 0 : scrollbarWidth());
  //   setWidth({ width: pageScrollEnabled ? 0 : scrollbarWidth(), immediate: true });
  // }, [pageScrollEnabled]);

  return width;
}

import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { Trigger } from "components/InterfaceOverlay/InterfaceTrigger/Trigger";

import { IApplicationState } from "store/index";
import * as fromInterfaceOverlay from "store/InterfaceOverlay";

const BackToWorkTriggerComponent: React.FC<{}> = (props) => {
  const active = useSelector(fromInterfaceOverlay.getBackToWorkTrigger);
  const dispatch = useDispatch();
  const enterBackToWorkTriggerAction = useCallback(
    () =>
      dispatch(
        fromInterfaceOverlay.actionCreators.enterBackToWorkTriggerAction()
      ),
    [dispatch]
  );
  const leaveBackToWorkTriggerAction = useCallback(
    () =>
      dispatch(
        fromInterfaceOverlay.actionCreators.leaveBackToWorkTriggerAction()
      ),
    [dispatch]
  );

  const handleMouseEnter = () => !active && enterBackToWorkTriggerAction();

  const handleMouseLeave = () => active && leaveBackToWorkTriggerAction();

  return (
    <Trigger
      active={active}
      title="Back to work"
      url="/work"
      align={"left"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    />
  );
};

export const BackToWorkTrigger = BackToWorkTriggerComponent;

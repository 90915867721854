import React, { useEffect, useState, useRef } from "react";
// import { useTrail, SpringConfig, Controller, UseTrailProps, Valid, PickAnimated, SpringValues, SpringRef } from '@react-spring/web';
import { useTrail, SpringConfig } from "@react-spring/web";
import { animateInSpringConfig } from "components/Animations/SpringProperties/SpringProperties";
import { useLatest } from "utils/index";

export interface IAnimateInProps {
  animationReady: boolean;
}

export interface INotify {
  index: number;
  threshold?: number;
}

export const fillNotifyArray = (
  amount: number = 1,
  initial: number = 0,
  gap: number = 1,
  threshold: number = 0
) => {
  const notify: INotify[] = [];
  for (let i = 0; i < amount; i++) {
    notify.push({
      index: initial + i * gap,
      threshold,
    });
  }
  return notify;
};

interface ITrailConfig {
  notify?: INotify[];
  immediate?: boolean;
  default?: boolean;
  customSpringConfig?: SpringConfig;
}

export const useAnimateInTrail = (
  animateInStatus: boolean = false,
  amount: number = 1,
  config: ITrailConfig = {
    notify: [],
    immediate: false,
    default: false,
    customSpringConfig: {},
  }
) => {
  const { notify, customSpringConfig, immediate, default: defaultt } = config;
  const latestNotify = useLatest(notify);

  const springConfig: SpringConfig = {
    ...(customSpringConfig !== {} ? customSpringConfig : animateInSpringConfig),
    mass: 1,
    clamp: true,
  };

  const started = useRef([]);
  const latestTriggerStart = useRef([]);
  const [triggerStart, setTriggerStart] = useState([]);

  const [animateInTrail, animateInTrailApi] = useTrail(amount, () => ({
    active: 1,
    from: { active: 0 },
    immediate,
    default: defaultt,
    config: springConfig,
    onChange: {
      active: (result: number, ctrl) => {
        if (started.current.indexOf(ctrl.id) == -1)
          started.current.push(ctrl.id);
        const currentIndex = started.current.indexOf(ctrl.id);
        const notifyProps =
          latestNotify.current !== undefined
            ? latestNotify.current.filter(
                (item) => item.index === currentIndex
              )[0]
            : undefined;
        if (notifyProps != undefined) {
          // Means we are looking to track this index
          const threshold =
            notifyProps.threshold > 0 ? notifyProps.threshold : 0;
          if (
            result > threshold &&
            latestTriggerStart.current.indexOf(currentIndex) === -1
          ) {
            const newTriggerStart = [
              ...latestTriggerStart.current,
              currentIndex,
            ];
            latestTriggerStart.current = newTriggerStart;
            // flushSync(() => { // WRAP IN flushSync FOR NEWER VERSIONS OF REACT TO ESCAPE STATE BATCHING INSTEAD OF SETTIMEOUT
            setTimeout(() => setTriggerStart(newTriggerStart), 1);
            // });
          }
        }
      },
    },
  }));

  const hasStarted = (index: number) => triggerStart.indexOf(index) > -1;

  useEffect(() => {
    // @ts-ignore
    animateInTrailApi.start({
      active: animateInStatus ? 1 : 0,
    });
  }, [animateInStatus]);

  type IReturnValues = [any, (index: number) => boolean, any];

  const returnValues: IReturnValues = [
    animateInTrail,
    hasStarted,
    animateInTrailApi,
  ];

  return returnValues;
};

import * as axios from 'axios';

const internalHeaders = {
  common: {
    Accept: 'application/json',
  },
  post: {
    'Content-Type': 'application/json',
  },
};

const xsrfToken = axios.default.create({
  method: 'get', // default
  baseURL: '/api/xsrfToken/',
  headers: internalHeaders,
});

export const api = axios.default.create({
  url: '',
  method: 'get', // default
  baseURL: '/api/',
  headers: internalHeaders,

  //    // `transformRequest` allows changes to the request data before it is sent to the server
  //    // This is only applicable for request methods 'PUT', 'POST', and 'PATCH'
  //    // The last function in the array must return a string, an ArrayBuffer, FormData, or a Stream
  //    transformRequest: [function (data) {
  //        // Do whatever you want to transform the data

  //        return data;
  //    }],

  //    // `transformResponse` allows changes to the response data to be made before
  //    // it is passed to then/catch
  //    transformResponse: [function (data) {
  //        // Do whatever you want to transform the data

  //        return data;
  //    }],

  //    // `headers` are custom headers to be sent
  //    headers: { 'X-Requested-With': 'XMLHttpRequest' },

  //    // `params` are the URL parameters to be sent with the request
  //    // Must be a plain object or a URLSearchParams object
  //    params: {
  //        ID: 12345
  //    },

  //    // `paramsSerializer` is an optional function in charge of serializing `params`
  //    // (e.g. https://www.npmjs.com/package/qs, http://api.jquery.com/jquery.param/)
  //    paramsSerializer: function (params) {
  //        return Qs.stringify(params, { arrayFormat: 'brackets' })
  //    },

  //    // `data` is the data to be sent as the request body
  //    // Only applicable for request methods 'PUT', 'POST', and 'PATCH'
  //    // When no `transformRequest` is set, must be of one of the following types:
  //    // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
  //    // - Browser only: FormData, File, Blob
  //    // - Node only: Stream
  //    data: {
  //        firstName: 'Fred'
  //    },

  //    // `timeout` specifies the number of milliseconds before the request times out.
  //    // If the request takes longer than `timeout`, the request will be aborted.
  //    timeout: 1000,

  //    // `withCredentials` indicates whether or not cross-site Access-Control requests
  //    // should be made using credentials
  //    withCredentials: false, // default

  //    // `adapter` allows custom handling of requests which makes testing easier.
  //    // Return a promise and supply a valid response (see lib/adapters/README.md).
  //    adapter: function (config) {
  //        /* ... */
  //    },

  //    // `auth` indicates that HTTP Basic auth should be used, and supplies credentials.
  //    // This will set an `Authorization` header, overwriting any existing
  //    // `Authorization` custom headers you have set using `headers`.
  //    auth: {
  //        username: 'janedoe',
  //        password: 's00pers3cret'
  //    },

  //    // `responseType` indicates the type of data that the server will respond with
  //    // options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
  //    responseType: 'json', // default

  //    // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
  //    xsrfCookieName: 'XSRF-TOKEN', // default

  //    // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
  //    xsrfHeaderName: 'X-XSRF-TOKEN', // default

  //    // `onUploadProgress` allows handling of progress events for uploads
  //    onUploadProgress: function (progressEvent) {
  //        // Do whatever you want with the native progress event
  //    },

  //    // `onDownloadProgress` allows handling of progress events for downloads
  //    onDownloadProgress: function (progressEvent) {
  //        // Do whatever you want with the native progress event
  //    },

  //    // `maxContentLength` defines the max size of the http response content allowed
  //    maxContentLength: 2000,

  //    // `validateStatus` defines whether to resolve or reject the promise for a given
  //    // HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
  //    // or `undefined`), the promise will be resolved; otherwise, the promise will be
  //    // rejected.
  //    validateStatus: function (status) {
  //        return status >= 200 && status < 300; // default
  //    },

  //    // `maxRedirects` defines the maximum number of redirects to follow in node.js.
  //    // If set to 0, no redirects will be followed.
  //    maxRedirects: 5, // default

  //    // `httpAgent` and `httpsAgent` define a custom agent to be used when performing http
  //    // and https requests, respectively, in node.js. This allows to configure options like
  //    // `keepAlive` that are not enabled by default.
  //    httpAgent: new http.Agent({ keepAlive: true }),
  //    httpsAgent: new https.Agent({ keepAlive: true }),

  //    // 'proxy' defines the hostname and port of the proxy server
  //    // `auth` indicates that HTTP Basic auth should be used to connect to the proxy, and supplies credentials.
  //    // This will set an `Proxy-Authorization` header, overwriting any existing `Proxy-Authorization` custom headers you have set using `headers`.
  //    proxy: {
  //        host: '127.0.0.1',
  //        port: 9000,
  //        auth: : {
  //        username: 'mikeymike',
  //        password: 'rapunz3l'
  //    }
  //  },

  //  // `cancelToken` specifies a cancel token that can be used to cancel the request
  //  // (see Cancellation section below for details)
  //  cancelToken: new CancelToken(function (cancel) {
  //  })
});

// A request interceptor that runs each time a call to the api is made
api.interceptors.request.use(async (config: any) => {
  // Do something before request is sent
  const xsrfTokenResponse = await getXsrfToken() as any;
  config.headers.common[xsrfTokenResponse.data.tokenName] = xsrfTokenResponse.data.token;                                                       // Setting current request xsrf headers to newly received tokens
  return config;
}, error => {
  return Promise.reject(error);
});

const getXsrfToken = () => {
  return new Promise((resolve, reject) => {
    xsrfToken.get('')
      .then(result => {
        //console.log('xsrfToken success:', result);
        resolve(result);
      })
      .catch(error => {
        console.log('xsrfToken error:', error);
        reject(error);
      });
  });
};

//const XSRF_TOKEN_KEY = "xsrfToken";
//const XSRF_TOKEN_NAME_KEY = "xsrfTokenName";

//function setXsrfToken(config, {token, tokenName}: { token: string, tokenName: string }) {
//    window.sessionStorage.setItem(XSRF_TOKEN_KEY, token);
//    window.sessionStorage.setItem(XSRF_TOKEN_NAME_KEY, tokenName);
//    config.headers.common[tokenName] = token;                                                       // Setting current request xsrf headers to newly received tokens
//    api.defaults.headers.common[tokenName] = token;                                                 // Setting xsrf token as default on api calls just for reference, they are never used more than once
//}

export const fetchUser = userId => {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  api.get('Notes', {
  })
    .then(result => {
      console.log('success:', result);
      return result;
    })
    .catch(error => {
      console.log('error:', error);
      return error;
    });
};

export const websiteReport = data => {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  api.post('WebsiteError', data)
    .then(result => {
      console.log('success:', result);
      return result;
    })
    .catch(error => {
      console.log('error:', error);
      return error;
    });
};

// export interface IApiResponse {
//   status: number;
//   statusDetails: string;
//   message: string;
//   data: string;
// }
const stripeUrl = 'stripe';

export interface IStripeForm {
  name: string;
  phone: string;
  email: string;
}

export const createCustomerAndSetupIntent = (form: IStripeForm) => {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  return api.post(stripeUrl + "/createCustomerAndSetupIntent", form, {})
    .then((result: axios.AxiosResponse) => {
    console.log('success from stripe:', result);
    return result;
  })
      .catch((error: axios.AxiosResponse) => {
        console.log('error from api:', error);
        throw error;
      });
};

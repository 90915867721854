import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";

import { useSpring, a, SpringConfig } from "@react-spring/web";
import { defaultSpringConfig } from "components/Animations/SpringProperties/SpringProperties";

import BlockLink from "components/Links/BlockLink/BlockLink";

import * as fromUserInterface from "store/UserInterface";
import * as fromMainNavigation from "store/MainNavigation";

import { useTimeout, useLatest } from "utils/Index";

export type IAlign = "left" | "right";

interface IOwnProps {
  active: boolean;
  title: string;
  url: string;
  align: IAlign;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

type IProps = IOwnProps;

const InterfaceTriggerComponent: React.FC<IProps> = ({
  active,
  title,
  url,
  align,
  ...props
}) => {
  const animateContentIn = useSelector(
    fromUserInterface.getPageContentAnimatedIn
  );

  const navMenuOpen = useSelector(fromMainNavigation.getNavMenuOpen);

  const [animateIn, setAnimateIn] = useState(false);
  const [hovering, setHovering] = useState(false);

  const [delay, setDelay] = useState(2000);
  const [initialised, setInitialised] = useState(false);
  const [triggerTimeout, setTriggerTimeout] = useState(false);

  const springConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 400,
  };
  const [hoveringSpring, setHoveringSpring] = useSpring(() => ({
    active: 0,
    config: springConfig,
  }));

  useEffect(() => {
    setHoveringSpring({ active: active ? 1 : 0 });
  }, [active]);

  const [animateInSpring, setAnimateInSpring] = useSpring(() => ({
    active: 0,
    config: springConfig,
  }));

  useEffect(() => {
    setAnimateInSpring({ active: animateIn ? 1 : 0 });
  }, [animateIn]);

  const latestInitialised = useLatest(initialised);

  useTimeout(
    () => {
      if (!latestInitialised.current) {
        handleTriggerMouseLeave();
        setInitialised(true);
      }
      setTriggerTimeout(false);
    },
    triggerTimeout ? delay : null
  );

  useEffect(() => {
    if (animateContentIn && !animateIn) {
      setAnimateIn(true);
      if (!initialised) {
        showTrigger();
        setTriggerTimeout(true);
      }
    } else if (!animateContentIn && animateIn && initialised) {
      // If we have already animated the trigger in, if we navigate to a new page, reset and animate in again
      setAnimateIn(false);
      setInitialised(false);
    }
  }, [animateContentIn, animateIn, initialised, hovering]);

  const showTrigger = () => {
    if (props.onMouseEnter) props.onMouseEnter();
    if (!hovering) setHovering(true);
  };

  const handleTriggerMouseEnter = () => {
    showTrigger();
    if (!initialised) {
      setInitialised(true);
    }
  };

  const handleTriggerMouseLeave = () => {
    if (props.onMouseLeave) props.onMouseLeave();
    if (hovering) setHovering(false);
  };

  const navSpringConfig: SpringConfig = {
    ...springConfig,
    tension: 170,
    friction: 36,
    clamp: false,
  };
  const [navSpring, setNavSpring] = useSpring(() => ({
    x: 0,
    config: navSpringConfig,
  }));

  useEffect(() => {
    setNavSpring({ x: navMenuOpen ? 1 : 0 });
  }, [navMenuOpen]);

  const wrapperStyles = {
    opacity: animateInSpring.active,
    transform: navSpring.x.to((x) => `translateY(${x * 100}px)`),
  };

  const triggerStyles = {
    width: hoveringSpring.active.to(
      (x) => `calc((${x} * (100% - 14px - 2px)) + 14px)`
    ),
    height: hoveringSpring.active.to(
      (x) => `calc((${x} * (100% - 14px - 2px)) + 14px)`
    ),
  };

  const labelStyles = {
    opacity: hoveringSpring.active,
  };

  const Button = (interactive: boolean = false) => (
    <a.div
      className={classNames(
        "interfaceTriggerWrapper",
        { border: interactive },
        { active },
        { alignLeft: align === "left" },
        { alignRight: align === "right" }
      )}
      style={wrapperStyles}
      onMouseEnter={interactive ? handleTriggerMouseEnter : undefined}
      onMouseLeave={interactive ? handleTriggerMouseLeave : undefined}
    >
      <div className="label">{title}</div>
      <a.div className="interfaceTrigger" style={triggerStyles}>
        <BlockLink to={url} title={title}>
          <a.div className="label" style={labelStyles}>
            {title}
          </a.div>
        </BlockLink>
        {interactive && <div className="smallTrigger" />}
      </a.div>
    </a.div>
  );

  return (
    <>
      {Button()}
      {Button(true)}
    </>
  );
};

export const Trigger = InterfaceTriggerComponent;
